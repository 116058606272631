/**
 * Modifies the muiTheme with the options from customTheme
 * 
 * @param {object} muiTheme 
 * @param {object} customTheme 
 */

export const modifyMui = (muiTheme, customTheme) => {
    const t = customTheme.ThemeOptions;
    muiTheme.customTheme = t;

    // Fonts
    const bodyFont = "prometo, sans-serif";
    muiTheme.bodyFont = "prometo, sans-serif";
    const headerFont = "prometo, sans-serif";
    muiTheme.headerFont = "prometo, sans-serif";
    
    muiTheme.overrides.MuiCssBaseline = {
        '@global': {
          'body': {
            fontFamily: bodyFont,
          },
          'h1, h2, h3, h4, h5, h6': {
            fontFamily: headerFont,
          }  
        },
    };
    muiTheme.typography = {
        fontFamily: bodyFont,
        useNextVariants: true,
    };

    // Colours
    muiTheme.palette = {
        primary: {
            "main": t.mainColours.primaryColour,
            "light": shadeBlend(0.1, t.mainColours.primaryColour),
            "dark": shadeBlend(-0.1, t.mainColours.primaryColour),
            "contrastText": t.foregroundColours.foregroundPrimaryColour
        },
        secondary: {
            "main": t.mainColours.secondaryColour,
            "light": shadeBlend(0.1, t.mainColours.secondaryColour),
            "dark": shadeBlend(-0.1, t.mainColours.secondaryColour),
            "contrastText": t.foregroundColours.foregroundSecondaryColour
        },
        tertiary: {
            "main": t.mainColours.tertiaryColour,
            "light": shadeBlend(0.1, t.mainColours.tertiaryColour),
            "dark": shadeBlend(-0.1, t.mainColours.tertiaryColour),
            "contrastText": t.foregroundColours.foregroundfertiaryColour
        },
        text: {
            primary: t.generalColours.black,
            secondary: t.generalColours.black
        },
        background: {
            "paper": t.generalColours.white,
            "default": t.generalColours.bodyBackground,
            "primary": t.backgroundColours.backgroundPrimaryColour,
            "secondary": t.backgroundColours.backgroundSecondaryColour
        }
    };

    muiTheme.common = {
        "black": t.generalColours.black,
        "white": t.generalColours.white,
    };

    return muiTheme;
}

function shadeBlend(p,c0,c1) {
    var n=p<0?p*-1:p,u=Math.round,w=parseInt;
    if(c0.length>7){
        var f=c0.split(","),t=(c1?c1:p<0?"rgb(0,0,0)":"rgb(255,255,255)").split(","),R=w(f[0].slice(4)),G=w(f[1]),B=w(f[2]); // eslint-disable-line
        return "rgb("+(u((w(t[0].slice(4))-R)*n)+R)+","+(u((w(t[1])-G)*n)+G)+","+(u((w(t[2])-B)*n)+B)+")" // eslint-disable-line
    }else{
        var f=w(c0.slice(1),16),t=w((c1?c1:p<0?"#000000":"#FFFFFF").slice(1),16),R1=f>>16,G1=f>>8&0x00FF,B1=f&0x0000FF; // eslint-disable-line
        return "#"+(0x1000000+(u(((t>>16)-R1)*n)+R1)*0x10000+(u(((t>>8&0x00FF)-G1)*n)+G1)*0x100+(u(((t&0x0000FF)-B1)*n)+B1)).toString(16).slice(1) // eslint-disable-line
    }
}