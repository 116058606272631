import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import themeData from "./muiTheme.json";
import themeOptions from "../../../.brew-cache/theme.json";
import {modifyMui} from "../modifyMui";

const themeName = 'Brew Core Theme';
const modifiedTheme = modifyMui(themeData, themeOptions);
let theme = createMuiTheme({ ...modifiedTheme, themeName });
theme = responsiveFontSizes(theme, { factor: 3 });

// Custom theme styles here or import them from wherever if they need to be broken

export default theme;

theme.headerTheme = {
    '&.MuiTypography-h1': {
        fontSize: 38,
        fontWeight: '800',
        letterSpacing: '-0.016em',
        lineHeight: 1.15,
        [theme.breakpoints.up('md')]: {
            fontSize: 64,
            lineHeight: 1.125,
        }
    },
    '&.MuiTypography-h2': {
        fontSize: 32,
        fontStyle: 'italic',
        letterSpacing: '-0.008em',
        lineHeight: 1.5,
        fontWeight: '800',
        [theme.breakpoints.up('md')]: {
            fontSize: 52,
            lineHeight: 1.23,
        }
    },
    '&.MuiTypography-h3': {
        fontSize: 28,
        lineHeight: 2.28,
        fontWeight: '700',
        [theme.breakpoints.up('md')]: {
            fontSize: 48,
            lineHeight: 1.33,
        }
    },
    '&.MuiTypography-h4': {
        fontSize: 20,
        lineHeight: 2.4,
        fontWeight: '700',
        letterSpacing: '0.008em',
        [theme.breakpoints.up('md')]: {
            fontSize: 32,
            lineHeight: 1.5,
        }
    },
    '&.MuiTypography-h5': {
        fontSize: 18,
        fontWeight: '500',
        lineHeight: 2.2,
        letterSpacing: '0.016em',
        [theme.breakpoints.up('md')]: {
            fontSize: 24,
            lineHeight: 1.66,
        }
    },
    '&.MuiTypography-h6': {
        fontSize: 16,
        fontWeight: '500',
        lineHeight: 1.5,
        letterSpacing: '0.016em',
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
        }
    },
}

theme.buttonTheme = {
    '& button': {
        fontSize: 18,
        lineHeight: 2.66,
        letterSpacing: '0.008em',
    }
}